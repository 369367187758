import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, Link, graphql } from "gatsby"
import styled from "@emotion/styled"

import TagsList from "./TagsList"

const PostsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -10px;
  margin-bottom: 20px;
`

const Post = styled.div`
  flex: 1 1 calc(50% - 20px);
  margin: 10px;
  width: calc(50% - 20px);
  padding: 10px;
  border: 1px solid #000;
  border-color: var(--border-color);
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out,
    border-color 250ms ease-in-out;
  :first-of-type {
    flex: 1 1 auto;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    flex: 1 1 auto;
  }
`

const FeaturedImg = styled.div`
  width: 100%;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  position: relative;
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    z-index: 0;
  }
  &:after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    transition: opacity 200ms ease-in-out;
  }
`

const PostTitle = styled.h2`
  margin: 20px 0 10px 0;
  font-family: "Trade Winds", cursive;
  font-weight: 400;
  font-style: normal;
  a {
    color: var(--postTitle);
    text-decoration: none;
    text-shadow: 2px 2px transparent;
    transition: color 200ms ease-in-out, text-shadow 200ms ease-in-out;
  }
  a:hover {
    color: var(--postTitleHover);
    text-shadow: 2px 2px black;
  }
`
const PostDate = styled.h4`
  margin: 10px 0;
`

export default function BlogPostList(props) {
  const { limit } = props
  return (
    <StaticQuery
      query={BlogListQuery}
      render={data => {
        let { edges: posts } = data.allMarkdownRemark
        if (limit > 0) {
          posts = posts.slice(0, limit)
        }
        return (
          <PostsContainer>
            {posts
              .filter(post => post.node.frontmatter.title.length > 0)
              .map(({ node: post }) => {
                // console.log({ img: post.frontmatter.featured })
                return (
                  <Post key={post.id}>
                    <Link
                      to={post.frontmatter.path}
                      aria-label={`Link to Post Entitled ${post.frontmatter.title}`}
                    >
                      <FeaturedImg className="featured-img-container">
                        <img 
                          src={
                            post.frontmatter.featured.childImageSharp.resize
                              .src
                          }
                          alt={post.frontmatter.title}
                        />
                      </FeaturedImg>
                    </Link>
                    <PostTitle className="post-title">
                      <Link
                        to={post.frontmatter.path}
                        aria-label={`Link to Post Entitled ${post.frontmatter.title}`}
                      >
                        {post.frontmatter.title}
                      </Link>
                    </PostTitle>
                    <PostDate>{post.frontmatter.date}</PostDate>
                    <p>{post.excerpt}</p>
                    <Link
                      to={post.frontmatter.path}
                      aria-label={`Link to Expand Post Entitled ${post.frontmatter.title}`}
                    >
                      Read more
                    </Link>
                    <TagsList tags={post.frontmatter.tags} />
                  </Post>
                )
              })}
          </PostsContainer>
        )
      }}
    />
  )
}

BlogPostList.propTypes = {
  limit: PropTypes.number,
}

BlogPostList.defaultProps = {
  limit: 0,
}

const BlogListQuery = graphql`
  query BlogListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            featured {
              childImageSharp {
                resize(width: 400) {
                  src
                  height
                  width
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
            path
            tags
            title
          }
        }
      }
    }
  }
`
