import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Bio from "../components/Bio"
import BlogPostList from "../components/BlogPostList"
import PageHeading from "../components/PageHeading"
import SubHeading from "../components/SubHeading"
import TertiaryHeading from "../components/TertiaryHeading"
import LinkContainer from "../components/LinkContainer"

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        ogImg: file(relativePath: { eq: "images/handy_pro-og.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout>
          <SEO
            title="Home"
            keywords={[
              "Wesley L. Handy",
              "web developer",
              "full stack web developer",
              "javascript developer",
              "react developer",
              "developer in Virginia Beach",
              "developer in Hampton Roads",
              "developer in Chesapeake",
              "developer in Norfolk",
              "mongodb developer",
              "mysql developer",
              "node developer",
              "front end developer",
              "gatsby developer",
              "online store",
              "small business website",
              "ecommerce developer",
            ]}
            image={{
              src: data.ogImg.childImageSharp.fluid.src,
              height: "1200",
              width: "630",
              alt: "Wesley L. Handy, Full Stack Web Developer, at your service",
            }}
          />
          <PageHeading style={{ marginBottom: "0" }}>
            Wesley L. Handy
          </PageHeading>
          <SubHeading style={{ margin: "5px 0 30px 0" }}>
            Full Stack Web Developer
          </SubHeading>
          <TertiaryHeading>Located in Virginia Beach, VA</TertiaryHeading>
          <Bio text="Wesley L. Handy is a JavaScript specialist with a generalist disposition&mdash;a JavaScript aficionado and React ninja who can throw together fast and secure APIs with Node, Express, and MongoDB, while maintaining clean, efficient code. He loves learning new things, whether different languages such as C#, PHP, or Python, or different frameworks, like Gatsby, .Net, Wordpress, Drupal and others." />
          <TertiaryHeading>Recent Blog Posts</TertiaryHeading>
          <BlogPostList limit={6} />
          <LinkContainer>
            <Link to="/blog/">See More Posts</Link>
          </LinkContainer>
        </Layout>
      )
    }}
  />
)

export default IndexPage
